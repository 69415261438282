import * as React from "react"
import { Button } from "react-bootstrap"

import { carousel } from "./music-carousel.module.scss"

const MusicCarousel = ({ artists, index, setIndex }) => {
  const theta = (2 * Math.PI) / artists.length
  const [currentIndex, setCurrentIndex] = React.useState(index)
  const [imageRefs, setImageRefs] = React.useState([])
  const [maxHeight, setMaxHeight] = React.useState(300)

  React.useEffect(() => {
    // add or remove refs
    setImageRefs(imageRefs =>
      Array(artists.length)
        .fill()
        .map((_, i) => imageRefs[i] || React.createRef())
    )
    setMaxHeight(
      Math.max(...Array.from(imageRefs, x => x.current.clientHeight))
    )
  }, [artists.length, imageRefs])

  React.useEffect(() => {
    if (imageRefs[currentIndex])
      setMaxHeight(imageRefs[currentIndex].current.clientHeight)
  }, [imageRefs, currentIndex])

  const handleSelect = newIndex => {
    if (newIndex >= artists.length) newIndex = 0
    else if (newIndex < 0) newIndex = artists.length - 1
    window.history.replaceState(
      null,
      `${artists[newIndex].frontmatter.title} | Music | Renowned`,
      artists[newIndex].fields.slug
    )
    setIndex(newIndex)
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1)
    handleSelect(index + 1)
  }

  const handlePrev = () => {
    setCurrentIndex(currentIndex - 1)
    handleSelect(index - 1)
  }

  return (
    <div className={carousel}>
      <figure
        style={{
          transform: `rotateY(${currentIndex * -theta}rad)`,
          minHeight: `${maxHeight}px`,
        }}
      >
        {artists.map((artist, i) => (
          <img
            key={i}
            ref={imageRefs[i]}
            src={artist.frontmatter.image}
            alt={artist.frontmatter.title}
          />
        ))}
      </figure>
      <nav>
        <Button onClick={handlePrev}>
          PREV
        </Button>
        <Button onClick={handleNext}>
          NEXT
        </Button>
      </nav>
    </div>
  )
}

export default MusicCarousel
