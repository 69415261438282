import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MusicCarousel from "../components/music-carousel"
import Content, { HTMLContent } from "../components/content"

const MusicPageTemplate = ({
  contentComponent,
  pageContext,
  artists,
}) => {
  let currentIndex = 0
  for (let i = 0; i < artists.length; i++)
    if (artists[i].fields.slug === pageContext.slug) {
      currentIndex = i
      break
    }

  const PageContent = contentComponent || Content
  const [index, setIndex] = React.useState(currentIndex)
  const currentArtist = artists[index]

  return (
    <>
      <Seo
        title={
          pageContext.slug === "/music/"
            ? "Music"
            : currentArtist.frontmatter.title + " | Music"
        }
      />
      <MusicCarousel artists={artists} index={index} setIndex={setIndex} />
      <h2>{currentArtist.frontmatter.title}</h2>
      <PageContent content={currentArtist.html} />
    </>
  )
}

MusicPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  artists: PropTypes.object,
  initialIndex: PropTypes.number,
  title: PropTypes.string,
}

const MusicPage = ({ data, pageContext }) => {
  const artists = data.allMarkdownRemark.nodes

  return (
    <Layout title={"Music"}>
      <MusicPageTemplate
        contentComponent={HTMLContent}
        artists={artists}
        pageContext={pageContext}
      />
    </Layout>
  )
}

MusicPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default MusicPage

export const pageQuery = graphql`
  query Music {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(music)/" }
        fields: { slug: { ne: "/music/" } }
      }
    ) {
      nodes {
        id
        html
        frontmatter {
          title
          image
        }
        fields {
          slug
        }
      }
    }
  }
`
